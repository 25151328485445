import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import LoaderSubscription from "../Loader/MBLoading";
import queryString from "query-string";
import { getSubscriptionProductList } from "./Subscription/actions";
import { getActiveSubscriptionsBySite } from "./Subscription/actions";

const Subscription = () => {
  const { subscriptionProduct, activeSubscriptionsList, loading } = useSelector(
    state => state.subscription,
  );
  const { isAuth, user } = useSelector(state => state.Auth);

  const [subscriptionLimit, setSubscriptionLimit] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [alert, setAlert] = useState(false);
  const [isUserId, setIsUserId] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const parsed = queryString.parse(location.search);
  let urlValue = parsed.redirect_url;
  //console.log(alert);

  useEffect(() => {
    if (isAuth) {
      dispatch(getSubscriptionProductList({ site_id: 12, category_id: 0 }));
      dispatch(
        getActiveSubscriptionsBySite({ site_id: 12 }, status => {
          if (
            status.count === undefined &&
            !!user &&
            user.user_role === 3 &&
            (status.is_system_message_count >= 12 ||
              status.is_user_message_count >= 1 ||
              status.is_system_volume_count >= 12 ||
              status.is_user_volume_count >= 1)
          ) {
            setAlert(true);
          }
          if (
            urlValue ||
            (status.count === undefined &&
              !!user &&
              user.user_role === 3 &&
              (status.is_system_message_count >= 12 ||
                status.is_user_message_count >= 1 ||
                status.is_system_volume_count >= 12 ||
                status.is_user_volume_count >= 1))
          ) {
            setAlert(true);
          }
          if (status) {
            status.rows.map(item => setIsUserId(item.user_id));
          }
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, urlValue]);

  const getDurationInPerFormat = duration => {
    switch (Math.floor(duration / 30)) {
      case 1:
      case 2:
        return "/ month";
      case 3:
      case 4:
      case 5:
        return "/ 4-month";
      case 6:
      case 7:
      case 8:
        return "/ 6-month";
      case 9:
      case 10:
      case 11:
      case 12:
        return "/ year";
      default:
        return "";
    }
  };

  const getDurationInPerFormatName = duration => {
    switch (Math.floor(duration / 30)) {
      case 1:
      case 2:
        return " monthly";
      case 3:
      case 4:
      case 5:
        return " 4-month";
      case 6:
      case 7:
      case 8:
        return " 6-month";
      case 9:
      case 10:
      case 11:
      case 12:
        return " yearly";
      default:
        return "";
    }
  };

  const aSIds = useMemo(
    () =>
      !!activeSubscriptionsList && activeSubscriptionsList?.length
        ? activeSubscriptionsList.map(el => {
            const products = [];
            if (!!el.user_orders && el.user_orders?.length)
              el.user_orders.forEach(childEl => {
                childEl.user_order_items.forEach(i => {
                  products.push(i.product_id);
                });
              });
            return {
              next_payment_date: el.next_payment_date,
              user_subscription_id: el.user_subscription_id,
              products,
            };
          })
        : [],
    [activeSubscriptionsList],
  );

  const handleSubscription = () => {
    setSubscriptionLimit(true);
  };

  // const goBack = () => {
  //   window.location.replace(`${parsed.redirect_url}${location?.hash}`);
  // };

  //Subscription Modal Close
  // const handleSubscriptionPopupClose = () => {
  //   setAlert(false);
  // };

  const handleClickViewSubscription = product_id => {
    navigate(
      `${process.env.REACT_APP_REDIRECT_URL}view-subscription/${product_id}`,
    );
  };

  const handleClickSubscriptionConfirm = (product_id, site_id) => {
    const targetUrl = `${
      process.env.REACT_APP_NEW_ACCOUNT_REDIRECT_URL
    }subscription-confirmation?product_id=${product_id}&site_id=${+site_id}&redirect_url=${
      process.env.REACT_APP_NEW_BOARD_REDIRECT_URL
    }&is_direct=true&product_type=grow builder&from=master`;
    // navigate(
    //   `${
    //     process.env.REACT_APP_REDIRECT_URL
    //   }subscription-confirmation?product_id=${product_id}&site_id=${+site_id}&redirect_url=${
    //     process.env.REACT_APP_REDIRECT_URL
    //   }&is_direct=true&product_type=grow builder&from=master`
    // );
    window.open(targetUrl, "_blank");
  };

  return (
    <>
      <div className="right_bar subscription">
        <div className="breadcrumb-all">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a className="link-text" href="/builder">
                  Grow Creative Board
                </a>
                <svg
                  width="5"
                  height="8"
                  viewBox="0 0 5 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 1L4 4L1 7" stroke="#999999"></path>
                </svg>
              </li>

              <li className="breadcrumb-item">
                <span>Subscription</span>
              </li>
            </ol>
          </nav>
        </div>
        <h1 className="hello_title">Subscription</h1>

        {/* <!-- modal Subscription  --> */}
        {/* <Modal
          isOpen={alert}
          className="modal-dialog modal-dialog-centered"
          role="document"
          centered
        >
          <div className="modal-content">
            <div className="modal-body modal_popup">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => handleSubscriptionPopupClose()}
              >
                <img src={modalClose} alt="modal-close" />
              </button>
              <div className="modal_data editImageModalData subscriptionModal">
                <div className="row">
                  <div className="col-md-12 text-center mt-5">
                    <img src={deleteAlert} alt="red-info" />
                  </div>
                  <div className="col-md-12 text-center mb20 mt15">
                    <h1 className="fw-600 dark-text ">
                      {" "}
                      THANKS! "Sorry! As a Free Trial user, you are limited to
                      editing builds that only pertain to the 4-week series you
                      were provided."
                    </h1>
                  </div>
                  <div className="col-md-12 modal-btn">
                    <button
                      className="btn primary-btn"
                      onClick={() => handleSubscriptionPopupClose()}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal> */}

        {!!loading ? (
          <LoaderSubscription />
        ) : (
          <div className="right-main-card subscription-main-card">
            <div className="filter_nav w-100">
              <div
                className={
                  subscriptionLimit
                    ? "form-label alert alert-danger"
                    : "form-label alert alert-danger d-none"
                }
              >
                You are already having one active subscription. Please cancel
                that and try again.
              </div>
              <div className="row subscription-container">
                {/* {subscriptionProduct.length > 0 ? (
                  ""
                ) : (
                  <img src={growNoData} className="no-data-found d-flex"></img>
                )} */}
                {subscriptionProduct?.map((item, i) => {
                  return (
                    <div className="col-lg-6">
                      <div className="product-col">
                        <React.Fragment key={i}>
                          <h2 className="product-title">
                            {item?.product_name}
                          </h2>
                          {/* {!!aSIds.find(el =>
                            el.products.includes(item.product_id)
                          )?.next_payment_date &&
                            user?.user_id === isUserId && (
                              <div className="date-renewal">
                                Next Renewal :{" "}
                                {moment(
                                  aSIds.find(el =>
                                    el.products.includes(item.product_id)
                                  )?.next_payment_date
                                ).format("MMMM DD, yyyy")}
                              </div>
                            )} */}
                          {user?.user_id === isUserId ? (
                            <div className="card-subscription">
                              <div className="card-body body-subscription">
                                <h4 className="user_id_notification m-0">
                                  <b className="mr-1 price-month">
                                    ${item?.product_price || 0}
                                  </b>
                                  <span className="subscription-month">
                                    {getDurationInPerFormat(
                                      item?.product_duration,
                                    )}
                                  </span>
                                </h4>
                                {!!aSIds.find(el =>
                                  el.products.includes(item.product_id),
                                )?.next_payment_date &&
                                  user?.user_id === isUserId && (
                                    <div className="date-renewal">
                                      Next Renewal :{" "}
                                      {moment(
                                        aSIds.find(el =>
                                          el.products.includes(item.product_id),
                                        )?.next_payment_date,
                                      ).format("MMMM DD, yyyy")}
                                    </div>
                                  )}
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item?.product_description,
                                  }}
                                  className="product-desc"
                                />
                                <div className="main-subscription-div">
                                  {/* link */}
                                  {!!aSIds.find(el =>
                                    el.products.includes(item.product_id),
                                  ) ? (
                                    <button
                                      className="button-long"
                                      onClick={() =>
                                        handleClickViewSubscription(
                                          aSIds.find(el =>
                                            el.products.includes(
                                              item.product_id,
                                            ),
                                          )?.user_subscription_id,
                                        )
                                      }
                                    >
                                      Your Subscription
                                    </button>
                                  ) : activeSubscriptionsList?.filter(
                                      site => site.site_id === 3,
                                    )?.length > 0 ? (
                                    <div
                                      // target="_parent"
                                      className="subscription-btn"
                                      onClick={handleSubscription}
                                    >
                                      <button className="button-long">
                                        Try{" "}
                                        {getDurationInPerFormatName(
                                          item?.product_duration,
                                        )}{" "}
                                        Subscription
                                      </button>
                                    </div>
                                  ) : (
                                    <div
                                      // target="_parent"
                                      // to={{
                                      //   pathname: `${
                                      //     process.env.REACT_APP_REDIRECT_URL
                                      //   }subscription-confirmation?product_id=${
                                      //     item.product_id
                                      //   }&site_id=${+item?.site_id}&redirect_url=${
                                      //     process.env.REACT_APP_SITE_URL
                                      //   }/subscription`,
                                      // }}
                                      onClick={() =>
                                        handleClickSubscriptionConfirm(
                                          item?.product_id,
                                          12,
                                        )
                                      }
                                      className="subscription-btn"
                                    >
                                      <button className="button-long">
                                        Try{" "}
                                        {getDurationInPerFormatName(
                                          item?.product_duration,
                                        )}{" "}
                                        Subscription
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="card-body body-subscription">
                              <h4 className="user_id_notification m-0">
                                <b className="mr-1 price-month">
                                  ${item?.product_price || 0}
                                </b>
                                <span className="subscription-month">
                                  {getDurationInPerFormat(
                                    item?.product_duration,
                                  )}
                                </span>
                              </h4>
                              {!!aSIds.find(el =>
                                el.products.includes(item.product_id),
                              )?.next_payment_date &&
                                user?.user_id === isUserId && (
                                  <div className="date-renewal">
                                    Next Renewal :{" "}
                                    {moment(
                                      aSIds.find(el =>
                                        el.products.includes(item.product_id),
                                      )?.next_payment_date,
                                    ).format("MMMM DD, yyyy")}
                                  </div>
                                )}

                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item?.product_description,
                                }}
                                className="product-desc"
                              />

                              <div className="main-subscription-div">
                                {activeSubscriptionsList?.filter(
                                  site => site.site_id === 3,
                                )?.length > 0 ? (
                                  <div
                                    className="subscription-btn"
                                    onClick={handleSubscription}
                                  >
                                    {/* <span>
                                      Try{" "}
                                      {getDurationInPerFormatName(
                                        item?.product_duration
                                      )}{" "}
                                      Subscription
                                    </span> */}
                                    <button className="button-long">
                                      Try{" "}
                                      {getDurationInPerFormatName(
                                        item?.product_duration,
                                      )}{" "}
                                      Subscription
                                    </button>
                                  </div>
                                ) : (
                                  <div
                                    // target="_parent"
                                    // to={{
                                    //   pathname: `${
                                    //     process.env.REACT_APP_REDIRECT_URL
                                    //   }subscription-confirmation?product_id=${
                                    //     item.product_id
                                    //   }&site_id=${+item?.site_id}&redirect_url=${
                                    //     process.env.REACT_APP_SITE_URL
                                    //   }/subscription`,
                                    // }}
                                    onClick={() =>
                                      handleClickSubscriptionConfirm(
                                        item?.product_id,
                                        12,
                                      )
                                    }
                                    className="subscription-btn"
                                  >
                                    <button className="button-long">
                                      Try{" "}
                                      {getDurationInPerFormatName(
                                        item?.product_duration,
                                      )}{" "}
                                      Subscription
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Subscription;
