import React from "react";
import { Modal, Spinner } from "react-bootstrap";

const BasicModal = ({
  show = false,
  handleClose = () => {},
  title = "",
  children = () => <></>,
  isCancelBtn = true,
  cancelBtnText = "Cancel",
  handelCancelBtn = () => {},
  cancelBtnClass = "secondary-outline",
  isPrimaryBtn = false,
  primaryBtnText = "Save",
  handelPrimaryBtn = () => {},
  primaryBtnClass = "primary-btn",
  isDangerBtn = false,
  dangerBtnText = "Delete",
  handelDangerBtn = () => {},
  dangerBtnClass = "danger-button",
  loading = false,
  isSubmitButton = false,
  formName = "",
  size = "md",
  parentClass = "",
  subTitle = "",
  subTitleClass = "",
  isCloseButton = true,
  isFooterNeed = true,
  bodyParentClass = "",
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size={size}
      className={parentClass}
    >
      <Modal.Header closeButton={isCloseButton}>
        <div className="modal-title h4">
          {title}
          {!!subTitle && (
            <p className={`sub-title text-muted ${subTitleClass}`}>
              {subTitle}
            </p>
          )}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className={bodyParentClass}>{children}</div>
      </Modal.Body>
      {isFooterNeed ? (
        <Modal.Footer>
          {isCancelBtn && (
            <button onClick={handelCancelBtn} className={cancelBtnClass}>
              {cancelBtnText}
            </button>
          )}
          {isPrimaryBtn && (
            <button
              disabled={loading}
              onClick={handelPrimaryBtn}
              className={`${primaryBtnClass}`}
              type={isSubmitButton ? "submit" : "button"}
              form={!!formName ? formName : ""}
            >
              {loading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                ""
              )}{" "}
              {" " + primaryBtnText}
            </button>
          )}
          {isDangerBtn && (
            <button
              disabled={loading}
              onClick={handelDangerBtn}
              className={dangerBtnClass}
            >
              {loading ? (
                <Spinner animation="border" variant="white" size="sm" />
              ) : (
                ""
              )}{" "}
              {dangerBtnText}
            </button>
          )}
        </Modal.Footer>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default BasicModal;
