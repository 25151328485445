import { Navigate, Outlet } from "react-router-dom";
import { RouteConstants } from "./RouteConstants";
import { AddEditPage, BoardPage, HomePage, PreviewPage } from "./routeImports";
import Subscription from "../components/common/Subscription/Subscription";

const routesConfig = {
  common: [],
  private: [
    {
      path: RouteConstants.HOME,
      component: HomePage,
    },
    {
      path: RouteConstants.BOARD,
      component: () => <Outlet />,
      children: [
        {
          path: RouteConstants.ID,
          component: BoardPage,
          children: [
            { index: true, component: AddEditPage },
            { path: RouteConstants.PREVIEW, component: PreviewPage },
          ],
        },
      ],
    },
    {
      path: "/*",
      component: () => <Navigate to={RouteConstants.HOME} />,
    },
    {
      path: "subscription",
      component: Subscription,
    },
  ],
  public: [
    {
      path: RouteConstants.BOARD,
      component: () => <Outlet />,
      children: [
        {
          path: RouteConstants.ID,
          component: BoardPage,
          children: [{ path: RouteConstants.PREVIEW, component: PreviewPage }],
        },
      ],
    },
  ],
};

export default routesConfig;
