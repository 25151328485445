import React, { useState } from "react";
import BasicModal from "../../../../components/Modal/BasicModal";
import { useDispatch, useSelector } from "react-redux";
import { axiosCreativeBoard } from "../../../../services/api";
import { ACCEPT_REJECT_INVITATION } from "../../../../helper/url_helper";
import { toast } from "react-toastify";
import { fetchBoard, setInviteBoardData } from "../../../../store/actions";
// import { useNavigate } from "react-router";
import { removeQueryStringFromUrl } from "../../../../App";

const types = {
  ACCEPT: 1,
  REJECT: 2,
};

const InviteBoardAlert = ({ isOpen, onClose }) => {
  const [loading, setLoading] = useState(0);
  const {
    inviteBoardData: { fromUser, boardTitle, inviteBoardId },
  } = useSelector(state => state.Auth);
  const boardData = useSelector(state => state.Home);
  const dispatch = useDispatch();

  const handleConfirmOrReject = async value => {
    try {
      setLoading(value);
      const res = await axiosCreativeBoard.post(ACCEPT_REJECT_INVITATION, {
        invite_board_user_id: inviteBoardId,
        status: value,
      });

      if (res.status) {
        setLoading(0);
        removeQueryStringFromUrl();
        dispatch(
          setInviteBoardData({
            inviteBoardData: {
              fromUser: "",
              boardTitle: "",
              inviteBoardId: 0,
            },
          }),
        );

        let payload = {
          page_no: 1,
          page_record: boardData.sizePerPage,
          search: "",
          sort_field: "updated_datetime",
          sort_order: "DESC",
          type: 1,
        };
        dispatch(fetchBoard(payload));
        onClose();
      }
    } catch (err) {
      setLoading(0);
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <div>
      <BasicModal
        show={isOpen}
        handleClose={onClose}
        title="Board Invitation!"
        primaryBtnText="Accept"
        isPrimaryBtn={true}
        cancelBtnText="Reject"
        handelCancelBtn={() => handleConfirmOrReject(types.REJECT)}
        handelPrimaryBtn={() => handleConfirmOrReject(types.ACCEPT)}
        loading={loading === types.ACCEPT}
      >
        <p>
          {fromUser} invited you to board ({boardTitle}).
        </p>
      </BasicModal>
    </div>
  );
};

export default InviteBoardAlert;
