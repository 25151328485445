import { toast } from "react-toastify";
import {
  DELETE_BOARD,
  DUPLICATE_BOARD,
  GET_ACTIVE_SUBSCRIPTION_URL,
  GET_BOARD_TOTAL,
  LIST_BOARD,
  UPDATE_BOARD_IMAGE,
} from "../../../helper/url_helper";
import { axiosAccount, axiosCreativeBoard } from "../../../services/api";

import {
  FETCH_BOARD,
  FETCH_BOARD_LOADING,
  SET_PAGE_NUMBER,
  HANDLE_CRUD_LOADING,
  SET_SORT_COLUMN_NAME,
  SET_SORT_COLUMN_ORDER,
  GET_TOTAL_BOARD_DATA,
  GET_ACTIVE_SUBSCRIPTION_LIST,
} from "./actionTypes";
import { SOMETHING_WANT_WRONG } from "../../../constants/ErrorMessage";

export const fetchBoard = payload => {
  return async dispatch => {
    try {
      dispatch(handleFetchBoardLoading(true));
      const res = await axiosCreativeBoard.post(LIST_BOARD, payload);
      if (res?.data?.data) {
        dispatch(handleFetchBoard(res.data.data));
        dispatch(getTotalBoardCounts());
        dispatch(handleFetchBoardLoading(false));
      }
    } catch (error) {
      dispatch(handleFetchBoardLoading(false));
      toast.error(error?.response?.data?.massage || SOMETHING_WANT_WRONG);
    }
  };
};

export const duplicateBoard = (payload, cb) => {
  return async dispatch => {
    try {
      dispatch(handleCRUDLoading(true));
      const res = await axiosCreativeBoard.post(DUPLICATE_BOARD, payload);
      if (res?.data) {
        cb(res);
        dispatch(handleCRUDLoading(false));
      }
    } catch (error) {
      dispatch(handleCRUDLoading(false));
      toast.error(error?.response?.data?.massage || SOMETHING_WANT_WRONG);
    }
  };
};

export const deleteBoard = (payload, cb) => {
  return async dispatch => {
    try {
      dispatch(handleCRUDLoading(true));
      const res = await axiosCreativeBoard.delete(DELETE_BOARD + "/" + payload);
      if (res?.data) {
        cb(res);
        dispatch(handleCRUDLoading(false));
      }
    } catch (error) {
      dispatch(handleCRUDLoading(false));
      toast.error(error?.response?.data?.massage || SOMETHING_WANT_WRONG);
    }
  };
};

// get tutorial list
export const getTotalBoardCounts = data => {
  return async dispatch => {
    try {
      let res = await axiosCreativeBoard.get(`${GET_BOARD_TOTAL}`);
      if (res.data?.data) {
        dispatch(setListTotalBoard(res.data.data.data));
      }
    } catch (err) {
      toast.error(err?.response?.data?.massage);
    }
  };
};

export const getActiveSubscriptionsBySite = ({ site_id }, cb = () => {}) => {
  return async dispatch => {
    try {
      // dispatch(setLoading(true));
      const res = await axiosAccount.get(
        `${GET_ACTIVE_SUBSCRIPTION_URL}/${site_id}`,
      );
      if (res?.data?.data) {
        dispatch(getActiveSubscriptionList(res.data.data));
        //cb(res.data.data);
      }
      // dispatch(setLoading(false));
    } catch (err) {
      // toast.error(err.response?.data?.message || err.message, {
      //   icon: ({ theme, type }) => (
      //     <img src={errorImg} className="toast-img" alt="error-img" />
      //   ),
      // });
      // dispatch(setLoading(false));
    }
  };
};

export const handleUpdateBoardImage = ({payload, cb}) => {
  return async () => {
    try{
      const res = await axiosCreativeBoard.post(UPDATE_BOARD_IMAGE, payload);
      if(res.status) {
        cb();
      }
    }catch(err){
      toast.error(err?.response?.data?.massage);
    }
  }
}

export const setListTotalBoard = data => ({
  type: GET_TOTAL_BOARD_DATA,
  payload: data,
});

export const handleFetchBoardLoading = data => ({
  type: FETCH_BOARD_LOADING,
  payload: data,
});

export const handleFetchBoard = data => ({
  type: FETCH_BOARD,
  payload: data,
});

export const setPageNumber = data => ({ type: SET_PAGE_NUMBER, payload: data });

export const setSortColumnName = data => ({
  type: SET_SORT_COLUMN_NAME,
  payload: data,
});
export const setSortColumnOrder = data => ({
  type: SET_SORT_COLUMN_ORDER,
  payload: data,
});

export const handleCRUDLoading = data => ({
  type: HANDLE_CRUD_LOADING,
  payload: data,
});
export const getActiveSubscriptionList = data => {
  return {
    type: GET_ACTIVE_SUBSCRIPTION_LIST,
    payload: data,
  };
};
