export const FETCH_BOARD_LOADING = "FETCH_BOARD_LOADING";
export const FETCH_BOARD = "FETCH_BOARD";

export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";
export const SET_SORT_COLUMN_NAME = "SET_SORT_COLUMN_NAME";
export const SET_SORT_COLUMN_ORDER = "SET_SORT_COLUMN_ORDER";

export const HANDLE_CRUD_LOADING = "HANDLE_CRUD_LOADING";

export const SET_IS_GRID_VIEW = "SET_IS_GRID_VIEW";

//total counts
export const GET_TOTAL_BOARD_DATA = "GET_TOTAL_BOARD_DATA";
export const GET_ACTIVE_SUBSCRIPTION_LIST = "GET_ACTIVE_SUBSCRIPTION_LIST";
