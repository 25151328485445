import React from "react";
import logo from "../../assets/images/growFaviconBig.png";

const MainLoader = () => {
  return (
    <div className="centered flex-column main-loader">
      <img src={logo} alt="stuff-you-can-use logo" className="avatar-lg" />
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default MainLoader;
