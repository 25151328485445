import { toast } from "react-toastify";
import {
  LOG_OUT_USER,
  USER_PROFILE,
  VERIFY_BOARD_TOKEN,
} from "../../helper/url_helper";
import { axiosAccount, axiosCreativeBoard } from "../../services/api";
import { clear, get } from "../../services/cookies";
import { clearLocal } from "../../services/localStorage";
import {
  FETCH_USER,
  FETCH_USER_LOADING,
  LOGOUT,
  SET_CHATBOT_VISIBILITY,
  SET_INVITE_BOARD_DATA,
  SET_IS_AUTH,
  SET_TOKEN,
} from "./actionTypes";

export const fetchUserProfile = payload => {
  return async dispatch => {
    try {
      dispatch(fetchUserLoading(true));
      const res = await axiosAccount.get(USER_PROFILE);
      if (res?.data?.data?.user) {
        dispatch(fetchUserSuccess(res.data?.data?.user));
        dispatch(fetchUserLoading(false));
      }
    } catch (error) {
      dispatch(fetchUserLoading(false));
      toast.error(error?.response?.data?.massage || "Something went's wrong");
    }
  };
};

export const logOutUser = () => {
  return async dispatch => {
    try {
      dispatch(fetchUserLoading(true));
      const token = get("token");
      await axiosAccount.post(LOG_OUT_USER, {
        token: token,
      });
      clear();
      clearLocal();
      dispatch(logout());
      window.location.reload();
      dispatch(fetchUserLoading(false));
    } catch (err) {
      dispatch(fetchUserLoading(false));
      toast.error(err.response?.data?.message || err.message);
    }
  };
};

export const verifyInvitation =
  ({ token, onInvitationVerificationError }, cb) =>
  async dispatch => {
    try {
      const res = await axiosCreativeBoard.post(VERIFY_BOARD_TOKEN, {
        token,
      });
      if (res.status === 200) {
        dispatch(fetchUserLoading(false));
        if (res.data?.data) {
          const { display_name, board_name, invite_board_user_id } =
            res.data.data;
          dispatch(
            setInviteBoardData({
              fromUser: display_name,
              boardTitle: board_name,
              inviteBoardId: invite_board_user_id,
            }),
          );
        }
        cb(true);
      }
    } catch (err) {
      dispatch(fetchUserLoading(false));
      toast.error(err.response?.data?.message || err.message);
      onInvitationVerificationError();
    }
  };

export const handleIsAuth = data => ({
  type: SET_IS_AUTH,
  payload: data,
});

export const handleSetToken = data => ({
  type: SET_TOKEN,
  payload: data,
});

export const logout = () => ({
  type: LOGOUT,
});

export const fetchUserLoading = data => ({
  type: FETCH_USER_LOADING,
  payload: data,
});

export const fetchUserSuccess = data => ({
  type: FETCH_USER,
  payload: data,
});

export const setChatBoxVisibility = data => ({
  type: SET_CHATBOT_VISIBILITY,
  payload: data,
});
export const setInviteBoardData = data => ({
  type: SET_INVITE_BOARD_DATA,
  payload: data,
});
