import {
  FiArrowUp,
  FiArrowUpRight,
  FiArrowRight,
  FiArrowDown,
  FiArrowDownRight,
  FiArrowDownLeft,
  FiArrowLeft,
  FiArrowUpLeft,
  FiRotateCcw,
} from "react-icons/fi";

export const Mobile_view_size = 767;

const gradientTypes = {
  LINEAR: "linear-gradient",
  RADIAL: "radial-gradient",
};

export const directions = [
  { id: 1, direction: "to top", type: gradientTypes.LINEAR, Icon: FiArrowUp },
  {
    id: 2,
    direction: "to top right",
    type: gradientTypes.LINEAR,
    Icon: FiArrowUpRight,
  },
  {
    id: 3,
    direction: "to right",
    type: gradientTypes.LINEAR,
    Icon: FiArrowRight,
  },
  {
    id: 4,
    direction: "to bottom right",
    type: gradientTypes.LINEAR,
    Icon: FiArrowDownRight,
  },
  {
    id: 5,
    direction: "to bottom",
    type: gradientTypes.LINEAR,
    Icon: FiArrowDown,
  },
  {
    id: 6,
    direction: "to bottom left",
    type: gradientTypes.LINEAR,
    Icon: FiArrowDownLeft,
  },
  {
    id: 7,
    direction: "to left",
    type: gradientTypes.LINEAR,
    Icon: FiArrowLeft,
  },
  {
    id: 8,
    direction: "to top left",
    type: gradientTypes.LINEAR,
    Icon: FiArrowUpLeft,
  },
  { id: 9, direction: "circle", type: gradientTypes.RADIAL, Icon: FiRotateCcw },
];

export const OS = {
  MAC: "Mac OS",
  IOS: "iOS",
  WINDOWS: "Windows",
  ANDROID: "Android",
  LINUX: "Linux",
};

export const keys = {
  [OS.WINDOWS]: "ctrlKey",
  [OS.LINUX]: "ctrlKey",
  [OS.MAC]: "metaKey",
};
