import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import Logo from "../assets/icons/Logo.svg";
import { useDispatch } from "react-redux";
// import { ReactSVG } from "react-svg";
// import mainSiteLogo from "../assets/images/Header/stuff-use-new4.png";
import { RouteConstants } from "../routes/RouteConstants";
import MyAccount from "./components/MyAccount";
import OtherTools from "./components/OtherTools";
import { fetchHeder } from "./store/actions";
import stuffYouCanUseLogo from "../assets/images/stuffyoucanuse.png";
import SiteLogo from "./../assets/images/GrowCreativeBoard.svg";
const Header = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchHeder({
        site_id: parseInt(process.env.REACT_APP_SITE_ID),
        menu_type: 1,
      }),
    );
  }, [dispatch]);

  return (
    <header style={{ height: 71 }}>
      <div className="main_logo">
        <Link to={RouteConstants.HOME}>
          <img src={SiteLogo} alt="Grow" style={{ height: 30 }} />
        </Link>
      </div>

      <div className="right_header" style={{ height: 30, marginTop: 2 }}>
        <img
          src={stuffYouCanUseLogo}
          alt="grow kid music"
          height={23}
          className="stuff-logo"
        />
        <OtherTools />
        <MyAccount />
      </div>
    </header>
  );
};

export default Header;
