import {
  FETCH_BOARD,
  FETCH_BOARD_LOADING,
  HANDLE_CRUD_LOADING,
  SET_PAGE_NUMBER,
  SET_IS_GRID_VIEW,
  SET_SORT_COLUMN_NAME,
  SET_SORT_COLUMN_ORDER,
  GET_TOTAL_BOARD_DATA,
  GET_ACTIVE_SUBSCRIPTION_LIST,
} from "./actionTypes";

import produce from "immer";

const initialState = {
  boardLoading: false,
  boardData: [],
  boardCount: 0,
  page: 1,
  sizePerPage: 10,
  CRUDLoading: false,
  isCard: true,
  sortField: "updated_datetime",
  sortOrder: "DESC",
  //total
  boardTotal: [],
  subscriptionDetail: {},
};

const authReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_BOARD_LOADING:
      return {
        ...state,
        boardLoading: payload,
      };
    case FETCH_BOARD:
      return {
        ...state,
        boardData: payload.rows,
        boardCount: payload.count,
      };
    case HANDLE_CRUD_LOADING:
      return {
        ...state,
        CRUDLoading: payload,
      };
    case SET_PAGE_NUMBER:
      return {
        ...state,
        page: payload,
      };

    case SET_IS_GRID_VIEW:
      return {
        ...state,
        isCard: payload,
      };

    case SET_SORT_COLUMN_NAME:
      return {
        ...state,
        sortField: payload,
      };
    case SET_SORT_COLUMN_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };
    case GET_TOTAL_BOARD_DATA:
      return {
        ...state,
        boardTotal: payload,
      };
    case GET_ACTIVE_SUBSCRIPTION_LIST:
      return {
        ...state,
        subscriptionDetail: {
          count: payload?.count,
          activeSubscriptionsList: payload.rows,
          systemMessageCount: payload.is_system_message_count,
          systemVolumeCount: payload.is_system_volume_count,
          userMessageCount: payload.is_user_message_count,
          userVolumeCount: payload.is_user_volume_count,
        },
      };
    default:
      return state;
  }
});

export default authReducer;
