import { LiveChatWidget } from "@livechat/widget-react";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "./assets/css/style.scss";
import Routes from "./routes";
import { get } from "./services/cookies";
import { useLocation } from "react-router-dom";
import {
  fetchUserLoading,
  fetchUserProfile,
  getTotalBoardCounts,
  handleIsAuth,
  handleSetToken,
  setChatBoxVisibility,
  verifyInvitation,
} from "./store/actions";
import useWindowScroll from "./hooks/useWindowScroll";
import InviteBoardAlert from "./modules/Board/components/Models/InviteBoardAlert";

const includeLiveChat = ["/"];

export const removeQueryStringFromUrl = () => {
  const updatedUrl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;
  window.history.pushState({ path: updatedUrl }, "", updatedUrl);
};

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { chatBoxVisibility } = useSelector(state => state.Auth);
  const [openInviteModal, setInviteModal] = useState(false);
  const scroll = useWindowScroll();

  const handleInvitation = () => {
    const query = new URLSearchParams(location.search);
    const inviteToken = query.get("invitation_token");
    if (!inviteToken) {
      dispatch(fetchUserLoading(false));
      return;
    }
    const onInvitationVerificationError = () => {
      removeQueryStringFromUrl();
    };
    dispatch(
      verifyInvitation(
        {
          token: inviteToken,
          onInvitationVerificationError,
        },
        val => setInviteModal(val),
      ),
    );
  };

  const handleInviteClose = () => {
    setInviteModal(false);
  };

  useEffect(() => {
    const token = get("token");
    if (!!!token && !location?.pathname.includes("preview")) {
      window.location.replace(
        `${process.env.REACT_APP_ACCOUNT_SITE_URL}/login?app_id=12&redirect=${process.env.REACT_APP_CREATIVE_BOARD_SITE_URL}`,
      );
    } else {
      if (!location?.pathname.includes("preview")) {
        dispatch(handleIsAuth(true));
        dispatch(handleSetToken(token));
        dispatch(fetchUserProfile());
        dispatch(getTotalBoardCounts());
      }
    }
    handleInvitation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, get("token")]);

  const handleVisibilityChanged = ({ visibility }) => {
    dispatch(setChatBoxVisibility(visibility));
  };

  const isAddLiveChat = useMemo(
    () => includeLiveChat.some(e => e === location.pathname),
    [location.pathname],
  );

  return (
    <>
      <ToastContainer autoClose={3000} />
      {isAddLiveChat && scroll.y < 500 ? (
        <LiveChatWidget
          onVisibilityChanged={handleVisibilityChanged}
          visibility={chatBoxVisibility}
          license="8932034"
        />
      ) : (
        ""
      )}
      {openInviteModal && (
        <InviteBoardAlert
          isOpen={openInviteModal}
          onClose={handleInviteClose}
        />
      )}
      <Routes />
    </>
  );
};

export default App;
