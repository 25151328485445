import { combineReducers } from "redux";
import Auth from "./Auth/reducer";
import Layout from "../layout/store/reducer";
import Home from "../modules/Home/store/reducer";
import Board from "../modules/Board/store/reducer";
import subscription from "../components/common/Subscription/Subscription/reducer";

const rootReducer = combineReducers({
  Auth,
  Layout,
  Home,
  Board,
  subscription,
});

export default rootReducer;
