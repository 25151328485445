import axios from "axios";
import store from "../store";
import { logout } from "../store/actions";
import { clear, get, set } from "./cookies";
import { clearLocal } from "./localStorage";

const API_URL_ADMIN = process.env.REACT_APP_API_ADMIN_URL;
const API_URL_ACCOUNT = process.env.REACT_APP_API_ACCOUNT_URL;
const API_URL_CREATIVE_BOARD = process.env.REACT_APP_API_CREATIVE_BOARD_URL;
// const API_URL_CREATIVE_BOARD = "http://192.168.1.121:8086/api/v1";
const API_URL_HUBS = process.env.REACT_APP_API_HUBS_URL;
class Axios {
  constructor(baseURL) {
    this.axios = axios.create({
      baseURL,
    });

    this.axios.interceptors.request.use(this._requestMiddleware);

    this.axios.interceptors.response.use(
      this._responseMiddleware,
      this._responseErr,
    );
  }

  _requestMiddleware = req => {
    // Send Bearer token on every request
    const token = get("token");
    if (!!token)
      req.headers.authorization = token.startsWith("Bearer ")
        ? token
        : "Bearer " + token;
    return req;
  };

  _responseMiddleware = response => {
    //  Do something on every success full response
    if (response?.data?.data?.token) {
      set("token", response.data.data.token);
    }
    return response;
  };

  _responseErr = error => {
    if (
      (error?.response?.data?.message).toString().toLowerCase() ===
      "permission reverted"
    ) {
      window.location.replace("/");
    }
    if (error?.response?.status === 401) return Promise.reject(error);
    else if (error?.response?.status === 401) {
      clear();
      clearLocal();
      store.dispatch(logout());
      return Promise.reject(error);
    }
    return Promise.reject(error);
  };

  // _responseErr = error => {
  //   if (
  //     (error?.response?.data?.message).toString().toLowerCase() ===
  //     "permission reverted"
  //   ) {
  //     window.location.replace("/");
  //   }

  //   if (
  //     error?.response?.status === 401 &&
  //     error?.response?.data?.message.toString().toLowerCase() !==
  //       "It seems you don't have permission to edit this board."
  //   ) {
  //     console.log("here");
  //     clear();
  //     clearLocal();
  //     store.dispatch(logout());
  //     return Promise.reject(error);
  //   }
  //   return Promise.reject(error);
  // };
}
const axiosAdmin = new Axios(API_URL_ADMIN).axios;
const axiosAccount = new Axios(API_URL_ACCOUNT).axios;
const axiosCreativeBoard = new Axios(API_URL_CREATIVE_BOARD).axios;
const axiosHubs = new Axios(API_URL_HUBS).axios;
export { axiosAdmin, axiosAccount, axiosCreativeBoard, axiosHubs };
