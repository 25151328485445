import lazy from "../utils/lazy";

export const HomePage = lazy(
  () => import(/* webpackChunkName: "HomePage" */ "../modules/Home/page"),
);

export const BoardPage = lazy(
  () => import(/* webpackChunkName: "BoardPage" */ "../modules/Board/pages"),
);

export const PreviewPage = lazy(
  () =>
    import(
      /* webpackChunkName: "PreviewPage" */ "../modules/Board/pages/BoardPreview"
    ),
);

export const AddEditPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AddEditPage" */ "../modules/Board/pages/AddEditBoard"
    ),
);
