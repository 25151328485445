import React, { useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import allSitesLogo from "../../assets/images/grid.svg";
import externalLink from "../../assets/images/external-link1.svg";
import arrowRight from "../../assets/images/angle-right.svg";
import { ReactSVG } from "react-svg";
import useOutsideClick from "../../hooks/useOutSideClick";
import { Link } from "react-router-dom";
import { setChatBoxVisibility } from "../../store/actions";


const OtherTools = () => {
  const { ninedotmenu, headerLoading } = useSelector(state => state.Layout);

  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => setShowDropdown(false));
  const dispatch = useDispatch();

  const handleToggle = isOpen => {
    setShowDropdown(isOpen);
  };

  const handleChatBox = (id) => {
    return ninedotmenu?.support?.data?.find((item) => item.nine_dot_menu_id === id)
  }

  return (
    <div className="catalog" ref={ref}>
      <Dropdown
        // onMouseLeave={() => setShowDropdown(false)}
        // onMouseOver={() => setShowDropdown(true)}
        onToggle={handleToggle}
        show={showDropdown}
      >
        <Dropdown.Toggle
          id="dropdown-autoclose-true"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <ReactSVG src={allSitesLogo} className="all-sites-icon" />
        </Dropdown.Toggle>

        <Dropdown.Menu show={showDropdown}>
          <ul>
          <h6 className="pt-0">{ninedotmenu?.curriculum?.curriculum_title}</h6>
            <div className="header-main-dropdown">
              {!!!headerLoading && !!ninedotmenu ? (
                ninedotmenu?.curriculum?.data
                  ?.map((item, i) => (
                    <li
                      key={i}
                      id={
                        item.title === "Grow Kids"
                          ? "grow-app-launcher-curriculum-grow-kids"
                          : "grow-app-launcher-curriculum-grow-students"
                      }
                      style={{ backgroundColor: "#444950" }}
                    >
                      <a href={item?.link} target="_blank" rel="noreferrer">
                        <div className="tools-inner">
                          <div className="d-flex justify-content-center">
                            <img
                              src={item?.icon} 
                              alt={item?.title}
                              height={28}
                              style={{ width: "100%", objectFit: "contain" }}
                            />
                          </div>
                        </div>
                      </a>
                    </li>
                  ))
              ) : (
                <li>Loading..</li>
              )}
            </div>
            <li>
              <hr className="dropdown-divider" style={{ height: "0px" }} />
            </li>
            <h6>{ninedotmenu?.tools?.tools_title}</h6>
            <div className="main-dropdown">
              {!!!headerLoading && !!ninedotmenu ? (
                // headerMenu?.slice(2)?.map((item, i) => (
                  ninedotmenu?.tools?.data?.map((item, i) => (
                  <li key={i}>
                    <a
                      href={item?.link}
                      rel="noreferrer"
                      target="_blank"
                      id={`grow-app-launcher-tool-${item?.title
                        .toLowerCase()
                        .split(" ")
                        .join("-")}`}
                    >
                      <div className="tools-inner">
                        <div
                          className="d-flex justify-content-center"
                          style={{ height: 40 }}
                        >
                          {/* <ReactSVG src={getIcon(item?.title)} /> */}
                          <img
                            src={item?.icon}
                            height={40}
                            width={40}
                            style={{ objectFit: "contain" }}
                            alt={item?.title}
                          />
                        </div>
                        <div className="tool-desc d-flex justify-content-center mt-2">
                          <span className="tool-desc-title">
                            {item?.title?.includes("Grow")
                              ? item.title.split(" ")[1] || item.title
                              : item.title}
                          </span>
                        </div>
                      </div>
                    </a>
                  </li>
                ))
              ) : (
                <li>Loading..</li>
              )}
            </div>
            <li>
              <hr className="dropdown-divider" style={{ height: "0px" }} />
            </li>
            <h6>{ninedotmenu?.support?.support_title}</h6>
            {ninedotmenu?.support?.data.map((item) => (
          <React.Fragment key={item.nine_dot_menu_id}>
           {item?.nine_dot_menu_id === 13 ? (
             <Link
              className="support-forum cursor-pointer mb-2"
              rel="noreferrer"
              id="grow-app-launcher-support-live-chat"
              onClick={() => {
                setShowDropdown(false);
                dispatch(setChatBoxVisibility("maximized"));
              }}
            >
              <div className="d-flex gap-2">
                {handleChatBox(item?.nine_dot_menu_id)?.icon && (
                  <span className="link-icon d-flex">
                    <img
                      src={handleChatBox(item?.nine_dot_menu_id)?.icon}
                      style={{ width: "16px", height: "14px" }}
                      alt=""
                    />
                  </span>
                )}
                <span className="d-flex align-items-center">
                  {handleChatBox(item?.nine_dot_menu_id)?.title}
                </span>
              </div>
              <span>
                <img
                  src={arrowRight} 
                  alt="arrowRight"
                  style={{ width: "6px", height: "13px" }}
                />
              </span>
            </Link>
          ) : (
            <a
              href={item?.link}
              className="support-forum cursor-pointer"
              id={`grow-app-launcher-${item?.title
                .toLowerCase()
                .split(" ")
                .join("-")}`}
              rel="noreferrer"
              target="_blank"
              style={{ marginBottom: "10px" }}
            >
              <div className="d-flex gap-2 justify-content-between w-100">
                <div className="d-flex gap-2">
                  {item?.icon && (
                    <span className="link-icon d-flex align-items-center">
                      <img
                        src={item?.icon}
                        style={{ width: "16px", height: "13px" }}
                        alt="icon"
                      />
                    </span>
                  )}
                  <span className="d-flex align-items-center">
                    {item?.title}
                  </span>
                </div>
                <div>
                  <span>
                    <img
                      src={externalLink} 
                      alt="support-external-link"
                      className="support-external-link"
                    />
                      </span>
                   </div>
                  </div>
                 </a>
                )}
              </React.Fragment>
             ))}
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default OtherTools;
