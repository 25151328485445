import React, { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
// import Sidebar from "./Sidebar";

const displaySidebarRoutes = ["/"];

const Layout = () => {
  const location = useLocation();

  const isDisplaySidebar = useMemo(
    () => displaySidebarRoutes?.some(e => e === location.pathname),
    [location.pathname],
  );

  return (
    <>
      {location.pathname.includes("preview") ? (
        <Outlet />
      ) : (
        <>
          <div className="separator"></div>
          <Header />
          <div className={isDisplaySidebar ? "main_part" : ""}>
            {/* {isDisplaySidebar && <Sidebar />} */}
            <Outlet />
          </div>
        </>
      )}
    </>
  );
};

export default Layout;
