import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import ContactIcon from "../../assets/icons/contact.svg";
// import DashboardIcon from "../../assets/icons/dashboard.svg";
import SettingIcon from "../../assets/icons/setting.svg";
import SignOutIcon from "../../assets/icons/sign-out.svg";
// import TutorialIcon from "../../assets/icons/tutorial.svg";
import {
  // default as dummyUserIcon,
  default as UserIcon,
} from "../../assets/icons/user.svg";
import { logOutUser } from "../../store/actions";
import { ReactComponent as UserCircle } from "../../assets/images/Header/user-circle.svg";

const MyAccount = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.Auth);

  const handleLogout = () => {
    dispatch(logOutUser());
  };

  const redirectionHandler = value => {
    window.location.replace(
      process.env.REACT_APP_ACCOUNT_SITE_URL + "/?q=" + value,
    );
  };
  return (
    <div className="user">
      <Dropdown
        onMouseLeave={() => setShowDropdown(false)}
        onMouseOver={() => setShowDropdown(true)}
      >
        <Dropdown.Toggle
          id="dropdown-autoclose-true"
          style={{ backgroundColor: "white" }}
        >
          {user && user.profile_image ? (
            <img
              src={user.profile_image}
              alt="profile-img"
              height={30}
              width={30}
              style={{
                borderRadius: "50%",
                objectFit: "cover",
                marginTop: "1px",
              }}
            />
          ) : (
            <UserCircle />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu show={showDropdown}>
          <ul>
            {/* <li>
              <span
                className="dropdown-item cursor-pointer"
                onClick={() =>
                  window.location.replace(
                    process.env.REACT_APP_CURRICULUM_SITE_URL_2,
                  )
                }
              >
                <img src={DashboardIcon} alt="" />
                Grow Dashboard
              </span>
            </li> */}
            <li>
              <span
                className="dropdown-item cursor-pointer"
                onClick={() => redirectionHandler("1")}
              >
                <img src={UserIcon} alt="" />
                My Account
              </span>
            </li>
            <li>
              <span
                className="dropdown-item cursor-pointer"
                onClick={() => redirectionHandler("2")}
              >
                <img src={SettingIcon} alt="" />
                Billing & Subscriptions
              </span>
            </li>
            {/* <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <span
                className="dropdown-item cursor-pointer"
                onClick={() => redirectionHandler("4")}
              >
                <img src={TutorialIcon} alt="" />
                Tutorials
              </span>
            </li> */}
            {/* <li>
              <span
                className="dropdown-item cursor-pointer"
                onClick={() => redirectionHandler("5")}
              >
                <img src={ContactIcon} alt="" />
                Contact
              </span>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li> */}
            <li onClick={handleLogout}>
              <span className="dropdown-item cursor-pointer">
                <img src={SignOutIcon} alt="" />
                Sign out
              </span>
            </li>
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default MyAccount;
